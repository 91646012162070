import React from "react"
import { graphql } from 'gatsby'

import Layout from '../../layout'

import BackgroundContainer from '../../components/image/background-container'
import TitleContainer from '../../components/common/title-container'
import Title from '../../components/common/title'
import Link from '../../components/common/link'
import PageContainer from '../../components/common/page-container'
import SymfonyLogo from '../../assets/logos/symfony.svg'

export default (props) => (
    <Layout title="Symfony Development">
        <BackgroundContainer header={props.data.header}>
            <TitleContainer>
              <SymfonyLogo style={{ fill: '#fff', height: '5rem', marginBottom: '1.5rem' }} />

              <Title>Symfony Development</Title>
            </TitleContainer>
        </BackgroundContainer>
        <PageContainer>
          <h3>Symfony Application Development</h3>

          <p>
            The packagedby team have experience in developing a broad range of PHP applications. 
            This includes <Link href="/services/software-as-a-service">software as a service applications</Link>, back-office applications, as well as CMS-based and e-commerce websites. 
            packagedby can cover every phase of development of your application from planning to maintenance. 
            You can find out more about this by looking at <Link href="/process">our process</Link>.
          </p>

          <h3>Symfony API Development</h3>

          <p>
            If you are looking to develop a solution which can be consumed by multiple platforms i.e. a separate front-end and mobile apps. 
            We can develop secure, fully-tested APIs, as well as applications to consume the APIs.
          </p>


          <h3>Symfony API Integration</h3>

          <p>
            If you're looking to integrate an existing Symfony application with another service such as accounting software, 
            email marketing services, or SMS messaging services, packagedby can help you build a solution to accomplish this.
            We can also build reliable software libraries to easily integrate the service into your application. 
          </p>

          <h3>Symfony Consultancy</h3>

          <p>
            packagedby has advised many different organisations on their Symfony applications. 
            We can carry out technical reviews of your Symfony application and give comprehensive feedback on how the application is architected. 
            We can also provide <Link to="/services/technical-consultancy/due-diligence">technical due diligence</Link> prior to acquisition of or investment in a business which relies on applications built on Symfony.
          </p>

          <h3>Symfony Training</h3>

          <p>
            If you're looking to teach or upskill a development team on PHP, packagedby have delivered PHP training sessions across the UK and beyond.
            We offer a wide range of training programmes from the basics of PHP development, to developing and testing PHP applications using a framework such as Symfony.
          </p>
        </PageContainer>
    </Layout>
)

export const query = graphql`
  query {
    header: file(relativePath: { eq: "saas.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, maxHeight: 1000, cropFocus: CENTER) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
